<template>
  <div class="loginHome">
    欢迎使用智能巡检管理平台
  </div>
</template>

<script>
export default {
  name: "LoginHome"
}
</script>

<style scoped>
.loginHome{
  text-align: center;
  font-size: 80px;
  margin-top: 300px
}
</style>
